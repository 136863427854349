/**
 * create by wangchunyan1 on 2019/8/7
 */
import React from "react";

import "./mastery.less";

import LayoutYd from "../components/layoutYd";
import ContactUsYd from "../components/contactUs/contactUsYd";
import TipsYd from "../components/tips/tipsYd";
import ElegantDemeanorImgYd from "../components/elegantDemeanor/elegantDemeanorImgYd";

import masterBanner from "../images/banner_master.jpg";
import gexinghua from "../images/icon_gexinghua.png";
import bianjie from "../images/icon_bianjie.png";
import fuwu from "../images/icon_fuwu.png";
import shishihudong from "../images/icon_shishihudong.png";
import certificate from "../images/pic_certificate.png";
import elunchunPoster from "../images/pic_elunchunPoster.png";
import school1 from "../images/picSchool1Yd.jpg";
import school2 from "../images/picSchool2Yd.jpg";
import school3 from "../images/picSchool3Yd.jpg";
import school4 from "../images/picSchool4Yd.jpg";
import school5 from "../images/picSchool5Yd.jpg";
import school6 from "../images/picSchool6Yd.jpg";
import school7 from "../images/picSchool7Yd.jpg";
import school8 from "../images/picSchool8Yd.jpg";
import school9 from "../images/picSchool9Yd.jpg";
import school10 from "../images/picSchool10Yd.jpg";

import { Video } from "../utils/constant";
import SlideImg from "../components/slideImgs/slideImg";

class MasterY extends React.Component {

  render() {
    return (
      <LayoutYd>
        <div className='teacherBannerBox'>
          <img src={masterBanner} alt='校长'/>
        </div>
        <div className='teachAdvantage'>
          <h3 className='commonTitleH3Yd'>简单智课堂教学特点</h3>
          <p className='commonTitlePYd'>
            学习变简单，教育更公平
          </p>
          <div className='teacherAdvList'>
            <div className='teacherAdvColumn'>
              <dl>
                <dt>
                  <img src={gexinghua} alt='个性化教学'/>
                </dt>
                <dd>
                  <h3>个性化教学</h3>
                  <p>版内分层精准教学</p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <img src={bianjie} alt='操作便捷'/>
                </dt>
                <dd>
                  <h3>操作便捷</h3>
                  <p>完整硬件系统</p>
                </dd>
              </dl>
            </div>
            <div className='teacherAdvColumn'>
              <dl>
                <dt>
                  <img src={fuwu} alt='教研支持服务'/>
                </dt>
                <dd>
                  <h3>教研支持服务</h3>
                  <p>可持续的教学科研</p>
                </dd>
              </dl>
              <dl>
                <dt>
                  <img src={shishihudong} alt='实时互动'/>
                </dt>
                <dd>
                  <h3>实时互动</h3>
                  <p>学校常态使用数据输出</p>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <div className='solutionBox'>
          <h3 className='title'>能给学校解决什么问题？</h3>
          <p className='subTitle'>学习变简单，教育更公平</p>
          <div className='solutionList'>
            <div className='solutionColumn'>
              <dl>
                <dt>提高学校竞争力</dt>
                <dd>希望通过信息化手段，提升教学质量，提高学校竞争力的学校。</dd>
              </dl>
              <dl>
                <dt>实现个性化教学</dt>
                <dd>希望实现个性化教学，提高学生自主学习力的学校。</dd>
              </dl>
            </div>
            <div className='solutionColumn'>
              <dl>
                <dt>缺乏优秀教师</dt>
                <dd>因优秀师资缺乏，制约发展的学校。</dd>
              </dl>
              <dl>
                <dt>助力信息化验收</dt>
                <dd>助力完成教育部信息化验收任务。</dd>
              </dl>
            </div>
          </div>
        </div>
        <div className='accessTarget'>
          <h3 className='commonTitleH3Yd'>致力于达成的目标</h3>
          <p className='commonTitlePYd'>
            学习变简单，教育更公平
          </p>
          <div className='accessTargetImg'>
            <SlideImg from={"page_mastery"}/>
          </div>
        </div>

        <div className='zktResultBox'>
          <h3 className='commonTitleH3Yd'>效果如何</h3>
          <p className='commonTitlePYd'>
            学习变简单，教育更公平
          </p>
          <div className='zktResult'>
            <ElegantDemeanorImgYd/>
          </div>
          <div className='zktResultVideo'>
            <video className='ydVideo' preload="meta" controls controlsList='nodownload' poster={elunchunPoster} loop>
              <source src={Video.ELunChun}
                      type="video/mp4"/>
              你的浏览器不支持 html5 video播放
            </video>
          </div>
        </div>

        <div className='patentBox'>
          <h3 className='commonTitleH3Yd'>国家专利技术支撑</h3>
          <h4 className='patenBoxTitle'>
            创始团队来源于北京大学<br/>
            与北京大学共有国家专利<br/>
            （专利号：ZL200710099746.2） （专利种类：发明专利）
          </h4>
          <div className='patentImg'>
            <img src={certificate} alt={"专利"}/>
          </div>
        </div>
        <div className='brandBox'>
          <h3 className='commonTitleH3Yd'>我们的品牌主张</h3>
          <p className='commonTitlePYd'>
            学习变简单，教育更公平
          </p>
          <div className='brandList'>
            <div className='brandItem brandItem1'>
              <h5>简单校训</h5>
              <p>千难万阻简单应对，人生注定不简单</p>
            </div>
            <div className='brandItem brandItem2'>
              <h5>教学理念</h5>
              <p>自主学习力，终身竞争力</p>
            </div>
            <div className='brandItem brandItem3'>
              <h5>简单使命</h5>
              <p>科技让学习变简单，教育更公平</p>
            </div>
          </div>
        </div>

        <div className='demonstration'>
          <h3 className='title'>合作示范校</h3>
          <p className='subTitle'>学习变简单，教育更公平</p>
          <div className='demonstrationList'>
            <div>
              <img src={school1} alt={"内蒙古鄂伦春中学"}/>
              <span>内蒙古鄂伦春中学</span>
            </div>
            <div>
              <img src={school2} alt={"临汾行知学校"}/>
              <span>临汾行知学校</span>
            </div>
            <div>
              <img src={school3} alt={"河北邢台市清河中学"}/>
              <span>河北邢台市清河中学</span>
            </div>
            <div>
              <img src={school4} alt={"景德镇昌河中学"}/>
              <span>景德镇昌河中学</span>
            </div>
            <div>
              <img src={school5} alt={"重庆中山外国语学校"}/>
              <span>重庆中山外国语学校</span>
            </div>
            <div>
              <img src={school6} alt={"瑞丽民族中学"}/>
              <span>瑞丽民族中学</span>
            </div>
            <div>
              <img src={school7} alt={"安阳市第三十二中学"}/>
              <span>安阳市第三十二中学</span>
            </div>
            <div>
              <img src={school8} alt={"海南罗驿实验学校"}/>
              <span>海南罗驿实验学校</span>
            </div>
            <div>
              <img src={school9} alt={"四川自贡市旭川中学"}/>
              <span>四川自贡市旭川中学</span>
            </div>
            <div>
              <img src={school10} alt={"无为状元学校"}/>
              <span>无为状元学校</span>
            </div>
          </div>
        </div>
        <div className='contactUsYdContainer'>
          <ContactUsYd/>
        </div>
        <TipsYd/>
      </LayoutYd>
    );
  }
};
export default MasterY;
